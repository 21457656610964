@import '@/styles/breakpoints.module';

.promosSectionCtaContainer {
  text-align: center;

  & > * {
    width: 100%;
    @include tablet {
      width: auto;
    }
  }
}
